body.login {
	h1 a {
		background: url('../../images/logo.png') no-repeat top center;
		width: 142px;
		height: 19px;
		text-indent: -9999px;
		overflow: hidden;
		display: block;
	}
}
